@import "variable";

/*grey*/
.color-grey-100 {
  color: $grey-100;
}

.color-grey-200 {
  color: $grey-200;
}

.color-grey-300 {
  color: $grey-300;
}

.color-grey-400 {
  color: $grey-400;
}

.color-grey-500 {
  color: $grey-500;
}

.color-grey-600 {
  color: $grey-600;
}

.color-grey-700 {
  color: $grey-700;
}

.color-grey-800 {
  color: $grey-800;
}

.color-grey-900 {
  color: $grey-900;
}

/*red*/
.color-red-100 {
  color: $red-100;
}

.color-red-200 {
  color: $red-200;
}

.color-red-300 {
  color: $red-300;
}

.color-red-400 {
  color: $red-400;
}

.color-red-500 {
  color: $red-500;
}

.color-red-600 {
  color: $red-600;
}

.color-red-700 {
  color: $red-700;
}

.color-red-800 {
  color: $red-800;
}

.color-red-900 {
  color: $red-900;
}

/*teal*/
.color-teal-100 {
  color: $teal-100;
}

.color-teal-200 {
  color: $teal-200;
}

.color-teal-300 {
  color: $teal-300;
}

.color-teal-400 {
  color: $teal-400;
}

.color-teal-500 {
  color: $teal-500;
}

.color-teal-600 {
  color: $teal-600;
}

.color-teal-700 {
  color: $teal-700;
}

.color-teal-800 {
  color: $teal-800;
}

.color-teal-900 {
  color: $teal-900;
}

/*amber*/
.color-amber-100 {
  color: $amber-100;
}

.color-amber-200 {
  color: $amber-200;
}

.color-amber-300 {
  color: $amber-300;
}

.color-amber-400 {
  color: $amber-400;
}

.color-amber-500 {
  color: $amber-500;
}

.color-amber-600 {
  color: $amber-600;
}

.color-amber-700 {
  color: $amber-700;
}

.color-amber-800 {
  color: $amber-800;
}

.color-amber-900 {
  color: $amber-900;
}

.color-white {
  color: white;
}

