////
/// Top (Automatic)
/// @author Annaliet Iglesias
/// @access public
/// @group cf-helpers
////

/* Top (Automatic)
 -------------------------------------------------------------- */

$i : 0;

@while $i <= $base-total-top {
  .#{$prefix-helpers}-top-#{$i} {
    top : #{$i}px;
  }
  $i : $i + $base-interval-top;
}


// Example Generate:
//
//.cf-top-0 {
//  top: 0px !important;
//}
//
//.cf-top-10 {
//  top: 10px !important;
//}
//
//.cf-top-20 {
//  top: 20px !important;
//}
//
//.cf-top-30 {
//  top: 30px !important;
//}
//
//.cf-top-40 {
//  top: 40px !important;
//}