/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/styles/variable";
@import "./assets/styles/pallete-colors";
@import "./assets/styles/pallete-backgrounds";
@import "./assets/styles/helpers/cf";
@import "./assets/styles/sidebar";
@import "./assets/styles/helpers/bootstrap-classes";
// regular style toast
@import "~ngx-toastr/toastr.css";

//@font-face {
//  font-family: "GothamRounded_Book";
//  src: url("./assets/fonts/GothamRounded_Medium.otf");
//  font-weight: normal;
//  font-style: normal;
//}
//@font-face {
//  font-family: "Mangarica_Pro_Regular";
//  src: url("/assets/fonts/Mangerica/Mangerica_Pro_Regular.otf");
//}
//
//@font-face {
//  font-family: "Mangarica_Pro_Bold";
//  src: url("/assets/fonts/Mangerica/Mangerica_Pro_Bold.otf");
//}
//
//@font-face {
//  font-family: "GothamRounded_Light";
//  src: url("./assets/fonts/GothamRounded_Light.otf");
//}
//
//@font-face {
//  font-family: "Poppins-Light";
//  src: url("./assets/fonts/Poppins/Poppins-Light.ttf");
//}
//
//@font-face {
//  font-family: "Poppins";
//  src: url("./assets/fonts/Poppins/Poppins-Black.ttf");
//}
//
//@font-face {
//  font-family: "LeagueSpartan-Bold";
//  src: url("./assets/fonts/league-spartan/LeagueSpartan-Bold.otf");
//}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, sans-serif !important;
}

a {
  color: inherit !important;
  /* blue colors for links too */
  text-decoration: inherit !important;
  /* no underline */
}

.remove-material-form-padding-bottom {
  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }
}

.payment-method {
  color: white;
  background-color: $green-500;
  padding: 0px 21px;
  border-radius: 13px;
}

.enzona-mlc-backend {
  color: white;
  background-color: #e81e63;
  padding: 0px 12px;
  border-radius: 13px;
}

.pos-method {
  color: white;
  background-color: #673ab7;
  padding: 0px 12px;
  border-radius: 13px;
}

.enzona-backend {
  color: white;
  background-color: #e81e63;
  padding: 0px 12px;
  border-radius: 13px;
}

.efectivo-backend {
  color: white;
  background-color: #e81e63;
  padding: 0px 12px;
  border-radius: 13px;
}

.transferMovil-backend {
  color: white;
  background-color: #e81e63;
  padding: 0px 12px;
  border-radius: 13px;
}

.search-input {
  font-size: 20px;
  outline: none; // removes blue outline on focus
  border: 0; // removes border
  box-shadow: none;
}

.cuc-label {
  background: #9d28b0;
  color: white;
  padding: 0px 21px;
  border-radius: 13px;
}

.status-payed {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $green-700;
  color: white;
}

.status-modified {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $teal-700;
  color: white;
}

.status-processing-modified {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $teal-500;
  color: white;
}

.status-requested {
  padding: 4px 10px !important;
  border-radius: 5px !important;
  background-color: $amber-500 !important;
  color: white;
}

.status-approved {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $blue-500;
  color: white;
}

.status-processing-payment {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $green-500;
  color: white;
}

.status-confirmed {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $blue-500;
  color: white;
}

.status-requested {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $amber-500;
  color: white;
}

.status-processing-refunded {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $brown-300;
  color: white;
}

.status-cancelled {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $red-500;
  color: white;
}

.status-refunded {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $brown-500;
  color: white;
}

.status-consumed {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $blue-grey-500;
  color: white;
  position: unset !important;
}

.status-expired {
  padding: 4px 10px;
  border-radius: 5px;
  background-color: $grey-500;
  color: white;
  position: unset !important;
}

//
//span {
//  font-family: GothamRounded_Book, RobotoDraft, Roboto, "Helvetica Neue",
//  sans-serif, "Lato", sans-serif !important;
//}
// mat-label {
//background-color: white !important;
//padding-right: 3px !important;
// }

mat-form-field {
  font-size: 13px !important;
}

.mat-button {
  font-size: 12px !important;
}

.padding-right-320 {
  padding-right: 320px !important;
}

.custom-button[disabled] {
  background-color: #e0e0e0 !important;
  color: $grey-900 !important;
  height: fit-content;
}

.custom-button {
  height: fit-content;
  margin-top: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  background-color: $red-500 !important;
  color: white !important;
}

.star-icon {
  font-size: 16px !important;
  margin-left: 3px;
}

.responsive-padding {
  padding: 20px;
}

.md-drppicker[_ngcontent-c19]
.ranges[_ngcontent-c19]
ul[_ngcontent-c19]
li[_ngcontent-c19]
button.active[_ngcontent-c19] {
  background-color: $main-color !important;
  color: #fff;
}

.app-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
  border-top-width: 1px;
  border-top-style: solid;
}

div.md-drppicker {
  margin-bottom: 150px;
  width: 350px !important;
  min-width: 350px !important;
  //z-index: 10;
}

.select-column {
  width: 5% !important;
}

.cursor-hand {
  cursor: pointer;
}

.full-width {
  width: 100% !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.justify {
  text-align: justify !important;
}

blockquote {
  background: #f9f9f9;
  font-size: 1em;
  border-left: 10px solid $red-500;
  margin: 0.4em 0 1em;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: $red-500;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
  font-weight: 300;
  font-size: 1.3em;
}

.capitalize {
  text-transform: capitalize !important;
}

.main-color {
  color: $main-color;
}

.background-main-color {
  background-color: $main-color;
}

.background-color-white {
  background-color: white !important;
}

.my-primary-stroked-button {
  border: solid 2px $main-color !important;
}

.my-primary-stroked-button[ng-reflect-disabled="true"] {
  border: solid 2px $grey-300 !important;
}

.my-primary-button {
  mat-icon {
    color: white;
  }

  span {
    color: white;
    text-transform: uppercase !important;
  }
}

.my-stroked-button {
  border: solid 2px $grey-600 !important;

  span {
    color: $grey-600 !important;
    text-transform: uppercase;
  }
}

.my-warm-stroked-button {
  border: solid 2px $warn-color !important;

  span {
    color: $warn-color !important;
    text-transform: uppercase;
  }
}

.fine-letter {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.star-icon:before {
  color: $main-color !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.mat-expansion-panel-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

//for modals
.mat-dialog-container {
  border-radius: 20px !important;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12);

  padding-top: 0px !important;

  //padding-bottom: 0px !important;
  .mat-dialog-title {
    margin-top: 10px;
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.mat-list-item-content {
  width: 100% !important;
}

.mat-tooltip {
  font-size: 20px !important;
}

@media (max-width: 800px) {
  .th-selected-col {
    width: 10% !important;
  }

  .responsive-padding {
    padding: 10px;
  }

  .price-reservation-item {
    width: 50% !important;
  }

  .responsive_table {
    overflow-x: auto !important;
  }

  div.md-drppicker {
    width: 300px !important;
  }

  .mat-table {
    min-width: 800px;
  }

  // .mat-dialog-container {
  //padding-top: 0px !important;
  //padding-bottom: 0px !important;
  // }
}

.height-100 {
  height: 100% !important;
}

.display-none {
  display: none;
}

.display-unset {
  display: unset;
}

.ng5-slider .ng5-slider-pointer {
  background-color: $main-color !important;
}

.ng5-slider .ng5-slider-selection {
  background: $main-color !important;
}

.ng5-slider .ng5-slider-pointer.ng5-slider-active:after {
  background-color: $main-color !important;
}

.md-drppicker .ranges ul li button.active {
  background-color: $main-color !important;
  color: #fff;
}

.date-range .btn {
  background-color: $main-color !important;
}

.date-range .md-drppicker .btn {
  background-color: $main-color !important;
}

.btn.btn-default {
  background-color: $grey-100 !important;
}

.md-drppicker .btn:focus,
.md-drppicker .btn:hover {
  background-color: $main-color !important;
}

.buttons .buttons_input .btn {
  background-color: $main-color !important;
}

.md-drppicker td.active {
  background-color: $main-color !important;
}

.calendar-status-requested {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $amber-500;
  color: white;
}

.calendar-status-approved {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $blue-500;
  color: white;
}

.calendar-status-confirmed {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $green-500;
  color: white;
}

.calendar-status-cancelled {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $red-500;
  color: white;
}

.calendar-status-confirmed {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $green-500;
  color: white;
}

.calendar-status-consumed,
.status-expired {
  padding: 3px 10px;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  background-color: $grey-500;
  color: white;
}

button.mat-menu-item {
  line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
  margin-bottom: 14px;
}

.mat-icon svg {
  height: 24px;
  width: 24px;
}

// RESERVATION BUTTONS
.approve-button {
  border: solid 2px $blue-500 !important;
  background-color: $blue-500 !important;

  span {
    color: white !important;
    text-transform: uppercase !important;
  }
}

.reject-button {
  border: solid 2px $red-500 !important;

  span {
    color: $red-500 !important;
    text-transform: uppercase !important;
  }
}

.show-details-button {
  span {
    text-transform: uppercase !important;
  }
}

.timepicker__header {
  background-color: $main-color !important;
}

.timepicker-button {
  color: $main-color !important;
}

.mat-stroked-button {
  border: solid 2px !important;
}

ngx-material-timepicker-face {
  span.active {
    background-color: $main-color !important;
  }

  .clock-face__container {
    background-color: $main-color !important;
  }
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $grey-400;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: $grey-500;
}

.card-avatar {
  width: 86px;
  border-radius: 50%;
  position: absolute;
  bottom: 130px !important;
  border: 2px solid white;
  background-color: white;
  right: 40px;
}

.bold {
  font-weight: bold !important;
}

// .full-table-container {
//   height: calc(100vh - 67px) !important;
// }
.th-selected-col {
  width: 5%;
}

.mat-header-cell {
  padding-left: 5px !important;
}

.mat-cell {
  padding-left: 5px !important;
}

.header-components {
  padding: 20px;

  mat-icon {
    font-size: 30px;
    width: 30px;
    height: 30px;
  }

  span {
    font-size: 30px;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) {
    padding: 16px !important;

    mat-icon {
      font-size: 24px !important;
      width: 24px !important;
      height: 24px !important;
    }

    span {
      font-size: 24px !important;
    }
  }
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-shade-full {
  position: fixed !important;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  // background: transparent;
  background: rgba(0, 0, 0, 0.48) !important;
  z-index: 1024;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-div {
  background-color: white !important;
  border-radius: 16px !important;
  position: fixed !important;
  bottom: -100px !important;
  right: -100px !important;
  z-index: 9999 !important;
  width: 0px;
  height: 0px;
  transition: all 1s ease;

  .small-button {
    // width: 30px !important;
    // max-width: 30px !important;
    line-height: 30px;
    text-align: center;
    background-color: $main-color;
    color: white;
    padding: 0px 25px;
    font-size: 12px;
    border-radius: 16px;
    text-transform: uppercase;
  }

  .line-clamp-info {
    display: -webkit-box !important;
    -webkit-line-clamp: 1 !important;
    -webkit-box-orient: vertical !important;
    line-height: 1rem !important;
    height: 2rem !important;
    overflow: hidden;
  }
}

.info-panel-show {
  bottom: 20px !important;
  right: 10px !important;
  width: 250px !important;
  height: 70px;
  border: solid 1px $main-color !important;
  padding: 5px 10px !important;
  transition: width 1s ease, height 1s ease;
}

.info-panel-show-pay {
  bottom: 20px !important;
  right: 10px !important;
  width: 250px !important;
  height: auto !important;
  border: solid 1px $main-color !important;
  padding: 5px 10px !important;
  transition: width 1s ease, height 1s ease;
}

// FUSE Splash Screen CSS

#fuse-splash-screen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a237e;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%231f2984' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23232e8b' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23283492' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%232c3998' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23303f9f' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%233a48a6' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%234350ac' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%234c59b3' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%235462b9' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%235c6bc0' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  z-index: 99999;
  pointer-events: none;
}

#fuse-splash-screen .center {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#fuse-splash-screen .logo {
  width: 270px;
  margin: 0 auto;
}

#fuse-splash-screen .logo img {
  filter: drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.2));
}

#fuse-splash-screen .spinner-wrapper {
  display: block;
  position: relative;
  width: 100%;
  min-height: 100px;
  height: 100px;
}

#fuse-splash-screen .spinner-wrapper .spinner {
  position: absolute;
  overflow: hidden;
  left: 50%;
  margin-left: -50px;
  animation: outer-rotate 2.91667s linear infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .gap {
  position: absolute;
  left: 49px;
  right: 49px;
  top: 0;
  bottom: 0;
  border-top: 10px solid;
  box-sizing: border-box;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left,
#fuse-splash-screen .spinner-wrapper .spinner .inner .right {
  position: absolute;
  top: 0;
  height: 100px;
  width: 50px;
  overflow: hidden;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left .half-circle,
#fuse-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  position: absolute;
  top: 0;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  /*border              : 10px solid #F44436;     rumboservice*/
  border: 10px solid #f44436;
  /* autorecarga*/
  border-bottom-color: transparent;
  border-radius: 50%;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left {
  left: 0;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .left .half-circle {
  left: 0;
  border-right-color: transparent;
  animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: left-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .right {
  right: 0;
}

#fuse-splash-screen .spinner-wrapper .spinner .inner .right .half-circle {
  right: 0;
  border-left-color: transparent;
  animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
  -webkit-animation: right-wobble 1.3125s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

@keyframes outer-rotate {
  0% {
    transform: rotate(0deg) scale(0.5);
  }

  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes left-wobble {
  0%,
  100% {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

@keyframes right-wobble {
  0%,
  100% {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

@keyframes sporadic-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  100% {
    transform: rotate(1080deg);
  }
}

// FUSE Splash Screen CSS

// Edit Booking Dialog Style
.app-update-booking-dialog {
  height: 600px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: $grey-400;
    border-radius: 10px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: $grey-500;
  }
}
