////
/// Left (Automatic)
/// @author Annaliet Iglesias
/// @access public
/// @group cf-helpers
////

/* Left (Automatic)
 -------------------------------------------------------------- */

$i : 0;

@while $i <= $base-total-left {
  .#{$prefix-helpers}-left-#{$i} {
    left : #{$i}px;
  }
  $i : $i + $base-interval-left;
}


// Example Generate:
//
//.cf-left-0 {
//  left: 0px !important;
//}
//
//.cf-left-10 {
//  left: 10px !important;
//}
//
//.cf-left-20 {
//  left: 20px !important;
//}
//
//.cf-left-30 {
//  left: 30px !important;
//}
//
//.cf-left-40 {
//  left: 40px !important;
//}