/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
@use "@angular/material" as mat;

// Variables
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

$bodyfont: "Poppins", sans-serif;
$headingfont: "Poppins", sans-serif;
@import "@angular/material/theming";

/*Material Theme Colors*/

//$primary : mat-palette($mat-teal, 500);
//$primary-100 : mat-palette($mat-teal, 100);
$orange-daiquiri-theme: (
  50: #fafae6,
  100: #f2f1c2,
  200: #eae89b,
  300: #716470,
  400: #ded95c,
  500: #d9d344,
  600: #d7c23d,
  700: #1976d2,
  800: #e49916,
  900: #0d47a1,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$blue-daiquiri-theme: (
  50: #e8eaf2,
  100: #c4cbe0,
  200: #9faacb,
  300: #7a89b5,
  400: #5e6fa7,
  500: #ffc107,
  600: #3d4f90,
  700: #344584,
  800: #2d3c77,
  900: #222b60,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $black-87-opacity,
    900: white,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$primary: mat.define-palette($orange-daiquiri-theme, 900);
$primary-100: mat.define-palette($orange-daiquiri-theme, 100);
$primary-50: mat.define-palette($orange-daiquiri-theme, 50);
$accent: mat.define-palette($blue-daiquiri-theme, 500);
$warn: mat.define-palette(mat.$red-palette, 500);
$warn-100: mat.define-palette(mat.$red-palette, 100);
$theme: mat.define-light-theme($primary, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

@include mat.core();
$custom-typography: mat.define-typography-config(
  $font-family: "Roboto",
);
// Override the typography in the core CSS.
@include mat.core($custom-typography);
// Default Theme
$mat-theme-ignore-duplication-warnings: true;
@include mat.all-component-themes($theme);
// Dark Theme

/*Theme Colors*/
$topbar: mat.get-color-from-palette($primary);
$main-color: mat.get-color-from-palette($primary);
$warn-color: mat.get-color-from-palette($warn);
$main-color-100: mat.get-color-from-palette($primary-100);
$main-color-50: mat.get-color-from-palette($primary-50);
$warn-color-100: mat.get-color-from-palette($warn-100);
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4;
$sidebar-hover-icons: teal;
$font-16: 16px;
$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

/*bootstrap Color*/

$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primary: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/

$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;
$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e9ab2e;
$primary-dark: #6352ce;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/

$white: #fff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/

$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$card-brd: #d7dfe3;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;

/*Material Colors*/

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #eeeeee;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: #9e9e9e;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;
$red-100: #ffebee;
$red-200: #ffcdd2;
$red-300: #e57373;
$red-400: #ef5350;
$red-500: #f44336;
$red-600: #e53935;
$red-700: #d32f2f;
$red-800: #c62828;
$red-900: #b71c1c;
$amber-100: #ffecb3;
$amber-200: #ffe082;
$amber-300: #ffd54f;
$amber-400: #ffca28;
$amber-500: #ffc107;
$amber-600: #ffb300;
$amber-700: #ffa000;
$amber-800: #ff8f00;
$amber-900: #ff6f00;
$teal-50: #e0f2f2;
$teal-100: #b2dfdb;
$teal-200: #80cbc4;
$teal-300: #4db6ac;
$teal-400: #26a69a;
$teal-500: #009688;
$teal-600: #00897b;
$teal-700: #00796b;
$teal-800: #00695c;
$teal-900: #004d40;
$blue-50: #e3f2fd;
$blue-100: #bbdefb;
$blue-200: #90caf9;
$blue-300: #64b5f6;
$blue-400: #42a5f5;
$blue-500: #2196f3;
$blue-600: #1e88e5;
$blue-700: #1976d2;
$blue-800: #1565c0;
$blue-900: #0d47a1;
$green-50: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 50)
);
$green-100: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 100)
);
$green-200: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 200)
);
$green-300: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 300)
);
$green-400: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 400)
);
$green-500: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 500)
);
$green-600: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 600)
);
$green-700: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 700)
);
$green-800: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 800)
);
$green-900: mat.get-color-from-palette(
  mat.define-palette(mat.$green-palette, 900)
);
$brown-100: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 100)
);
$brown-200: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 200)
);
$brown-300: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 300)
);
$brown-400: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 400)
);
$brown-500: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 500)
);
$brown-600: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 600)
);
$brown-700: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 700)
);
$brown-800: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 800)
);
$brown-900: mat.get-color-from-palette(
  mat.define-palette(mat.$brown-palette, 900)
);
$blue-grey-100: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 100)
);
$blue-grey-200: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 200)
);
$blue-grey-300: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 300)
);
$blue-grey-400: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 400)
);
$blue-grey-500: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 500)
);
$blue-grey-600: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 600)
);
$blue-grey-700: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 700)
);
$blue-grey-800: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 800)
);
$blue-grey-900: mat.get-color-from-palette(
  mat.define-palette(mat.$blue-grey-palette, 900)
);

.main-primary {
  color: $main-color !important;
}

.bg-main-primary {
  background-color: $main-color !important;
}

.white {
  color: #fff !important;
}
