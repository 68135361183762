////
/// Align (Automatic)
/// @author Annaliet Iglesias
/// @author Victor Cruz
/// @access public
/// @group cf-helpers
////

/* Align Transform
 -------------------------------------------------------------- */

.#{$prefix-helpers}-align-transform-50 {
  transform         : translate3d(0, -50%, 0);
  -moz-transform    : translate3d(0, -50%, 0);
  -ms-transform     : translate3d(0, -50%, 0);
  -o-transform      : translate3d(0, -50%, 0);
  -webkit-transform : translate3d(0, -50%, 0);
}

/* Text Align (Automatic)
 -------------------------------------------------------------- */

///
/// Generate text align by map of elements.
///
/// @author Victor Cruz
///
/// @example scss
///   .cf-text-align-left {
///     text-align : left;
///   }
///
///   .cf-text-align-right {
///     text-align : right;
///   }
///
///   .cf-text-align-center {
///     text-align : center;
///   }
///
@mixin cf-text-align() {
  @each $value in left, right, center, justify {
    .#{$prefix-helpers}-text-align-#{$value} {
      text-align : #{$value};
    }
  }
}

@include cf-text-align();

/* Vertical Align (Automatic)
 -------------------------------------------------------------- */

///
/// Generate vertical align by map of elements.
///
/// @author Victor Cruz
///
/// @example scss
///   .cf-align-top {
///     vertical-align: top;
///   }
///
///   .cf-align-bottom {
///     vertical-align: bottom;
///   }
///
///   .cf-align-middle {
///     vertical-align: middle;
///   }
///
///   .cf-align-baseline {
///     vertical-align: baseline;
///   }
///
@mixin cf-vertical-align() {
  @each $value in top, bottom, middle, baseline {
    .#{$prefix-helpers}-align-#{$value} {
      vertical-align : #{$value};
    }
  }
}

@include cf-vertical-align();