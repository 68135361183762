////
/// Margin (Automatic)
/// @author Victor Cruz
/// @access public
/// @group cf-helpers
////

/* Margin (Automatic)
 -------------------------------------------------------------- */

@each $element, $name in (margin, Margin),
        (margin-top, Margin Top),
        (margin-bottom, Margin Bottom),
        (margin-left, Margin Left),
        (margin-right, Margin Right) {
  $i : 0;

  /* #{$name}
 -------------------------------------------------------------- */
  @while $i <= $base-total-margin {
    .#{$prefix-helpers}-#{$element}-#{$i} {
      #{$element} : #{$i}px !important;
    }
    $i : $i + $base-interval-margin;
  }
}

// Example Generate:

//__<<ngThemingMigrationEscapedComment2>>__
//.cf-margin-top-0 {
//  margin-top: 0px !important;
//}
//
//.cf-margin-top-20 {
//  margin-top: 20px !important;
//}
//
//__<<ngThemingMigrationEscapedComment3>>__
//.cf-margin-bottom-0 {
//  margin-bottom: 0px !important;
//}
//
//.cf-margin-bottom-20 {
//  margin-bottom: 20px !important;
//}
//
//__<<ngThemingMigrationEscapedComment4>>__
//.cf-margin-left-0 {
//  margin-left: 0px !important;
//}
//
//.cf-margin-left-20 {
//  margin-left: 20px !important;
//}
//
//__<<ngThemingMigrationEscapedComment5>>__
//.cf-margin-right-0 {
//  margin-right: 0px !important;
//}
//
//.cf-margin-right-20 {
//  margin-right: 20px !important;
//}